import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer_section bg-dark">
      <div className="container">
        <div className="row">
          <div className="col-md-4 footer-col">
            <div className="footer_contact">
              <h4>Contact Us</h4>
              <div className="contact_link_box">
              <div>
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1731.5488521443583!2d76.94731251908858!3d31.666606827054043!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39051d002191013d%3A0xe9179458a5b409f1!2sThe%20Robot%20Restaurant!5e1!3m2!1sen!2sus!4v1733856852765!5m2!1sen!2sus"
                   ></iframe>
                </div>
                <a href="#">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                  <span>Mandi,Himachal pradesh</span>
                </a>
                


                {/* <a href="#">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                  <span>Call +01 1234567890</span>
                </a> */}
                <a href="preeti.singh@botshreyasi.com">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                  <span>preeti.singh@botshreyasi.com</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 footer-col">
            <div className="footer_detail">
              <a href="#" className="footer-logo">
                The Robot Restaurent
              </a>
              <p>
                Experience gastronomy like never before with our robotic chefs
                crafting exquisite dishes right before your eyes.
              </p>
              <div className="footer_social">
                <a href="https://www.facebook.com/Passivereferral">
                  <i className="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="https://twitter.com/BotShreyasi">
                  <i className="fa fa-twitter" aria-hidden="true"></i>
                </a>
                <a href="https://www.linkedin.com/company/shreyasi-robotics/">
                  <i className="fa fa-linkedin" aria-hidden="true"></i>
                </a>
                <a href="https://www.instagram.com/the_robotrestaurant/">
                  <i className="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 footer-col">
            <h4>Opening Hours</h4>
            <p>Everyday</p>
            <p>10.00 AM - 10.00 PM</p>
          </div>
        </div>
        <div className="footer-info">
          <p>
            &copy; <span id="displayYear"></span> All Rights Reserved By
            <Link to={"/"}> The Robot Restaurent</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
