import { Link, useNavigate } from "react-router-dom";
import herobg from "../images/herobg.png";
import heroRobot from "../images/heroRobot.png";


import React, { useEffect, useState } from "react";
import { Navbar as N, Nav, Container } from "react-bootstrap";

const Navbar = () => {
  const [headingcontent, setHeadingContent] = useState("");
  const [paracontent, setParaContent] = useState("");

  const navigate = useNavigate();
  const currentPath = window.location.pathname;

  useEffect(() => {
    if (currentPath === "/About") {
      setHeadingContent("About");
      setParaContent(
        "The Robot Restaurant redefines dining with advanced robots serving exquisite cuisine in an innovative setting, offering a unique and futuristic culinary experience like no other.Welcome to the future of gastronomy at The Robot Restaurant."
      );
    } else if (currentPath === "/Franchise") {
      setHeadingContent("Request For Franchise");
      setParaContent(
        "Eager to bring the future of dining to your community? Inquire about franchising opportunities with The Robot Restaurant and become a part of the innovative culinary revolution where advanced robots serve delicious cuisine in a unique setting."
      );
    } else {
      setHeadingContent("The Robot Restaurant");
      setParaContent(
        "Experience the future of dining at The Robot Restaurant, where you can enjoy delicious cuisine served by advanced robots in a unique and innovative setting."
      );
    }
  }, [currentPath]);
  return (
    <>
      <N bg="dark" expand="lg" variant="dark" fixed="top">
        <Container>
          <N.Brand href="/" style={{fontWeight:'900'}}>The Robot Restaurant</N.Brand>
          <N.Toggle aria-controls="navbarNav" />
          <N.Collapse id="navbarNav">
            <Nav className="ml-auto" style={{ width:'400px'}}>
              <Nav.Link href="/" style={{marginLeft:'20px'}}>Home</Nav.Link>
              <Nav.Link href="/About" style={{marginLeft:'20px'}}>About</Nav.Link>
              <Nav.Link href="/Franchise" style={{marginLeft:'20px'}}>Request for Franchise</Nav.Link>
            </Nav>
          </N.Collapse>
        </Container>
      </N>

      <div
        className="container-fluid pt-5 hero-header mb-5"
        style={{ backgroundImage: `url(${herobg})` }}
      >
        <div className="container pt-5">
          <div className="row g-5 pt-5">
            <div className="col-lg-6 align-self-center text-center text-lg-start mb-lg-5">            
               
                  <div className="detail-box">
                    <h1 style={{ color: "white" }}>{headingcontent}</h1>
                    <p style={{ color: "white" }}>{paracontent}</p>
                  </div>
            </div>
            <div className="col-lg-6 align-self-end text-center text-lg-end">
              <img
                className="img-fluid"
                src={heroRobot}
                style={{ height: "500px" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
